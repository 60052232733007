import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Package } from '@/domain/entities/Package'
import { PackagePresenter } from '../presenters/PackagePresenter'
import { container } from 'tsyringe'
import Vue from 'vue'

export interface PackageState {
  isLoading: boolean
  packageData: Package
}

@Module({ namespaced: true, store, name: 'PackageController', dynamic: true })
class PackageController extends VuexModule {
  presenter: PackagePresenter = container.resolve(PackagePresenter)

  public isLoading = false
  public packageData = new Package()

  @Action({ rawError: true })
  public getPackage(id: string): void {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(resp => {
        this.setPackageData(resp)
      })
      .catch(error => {
        Vue.notify({
          title: 'Failed to request package data',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setPackageData(new Package())
      })
      .finally(() => this.setLoading(false))
  }

  @Mutation
  public setLoading(payload: boolean): void {
    this.isLoading = payload
  }

  @Mutation
  public setPackageData(payload: Package): void {
    this.packageData = payload
  }
}

export default getModule(PackageController)
